import axios from 'axios';

const baseURL = 'https://putni-nalozi-api.herokuapp.com/api/';

const getApiInstance = (jwt) =>
  axios.create({
    baseURL: baseURL,
    timeout: 2000,
    headers: {
      authorization: `Bearer ${jwt}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': '*',
    },
  });

const apiInstance = axios.create({
  baseURL: baseURL,
  timeout: 2000,
});

export { apiInstance, getApiInstance };
